import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import MeetTheTeam from "@components/sections/MeetTheTeam"
import LatestBlogPost from "@components/sections/LatestBlogPosts"
import JobsHero from "@components/sections/heros/JobsHero"
import { GlobalClientLogoGallery } from "@components/sections/GlobalClientLogoGallery"
import NoRecruiters from "@components/sections/NoRecruiters"
import CompanyValues from "@components/sections/CompanyValues"
import WellBeing from "@components/sections/WellBeing"
import JobFeedSection from "@components/sections/JobFeedSection"

const getSections = page => {
  if (!page.acfWorkForUs?.workForUsSections) return []
  const sections = []

  page.acfWorkForUs.workForUsSections.forEach((acfLayout, key) => {
    let section = null,
      niceName = null

    switch (acfLayout.fieldGroupName) {
      case "page_Acfworkforus_WorkForUsSections_JobFeed":
        niceName = "job_feed"
        const jobs = acfLayout?.currentJobs?.filter(
          job => job.status === "publish"
        )
        section = jobs && (
          <JobFeedSection
            jobs={jobs}
            title={acfLayout?.title}
            content={acfLayout.moreInfo}
          />
        )
        break
      case "page_Acfworkforus_WorkForUsSections_WeWorkWith":
        niceName = "we_work_with"
        section = <GlobalClientLogoGallery />
        break
      case "page_Acfworkforus_WorkForUsSections_OurTeam":
        niceName = "our_team"
        section = <MeetTheTeam />
        break
      case "page_Acfworkforus_WorkForUsSections_NoRecruitersPlease":
        niceName = "no_recruiters_please"
        section = <NoRecruiters />
        break
      case "page_Acfworkforus_WorkForUsSections_CompanyValues":
        niceName = "company_values"
        section = (
          <CompanyValues
            perks={acfLayout.perks}
            videoEmbed={acfLayout.videoEmbed}
            videoPlaceholder={acfLayout.videoPlaceholder}
            introContent={acfLayout.introContent}
          >
            <WellBeing points={acfLayout.wellbeing} />
          </CompanyValues>
        )
        break
      default:
        break
    }

    if (section) {
      const id = `${niceName}_${key}`
      sections.push(
        <div key={id} id={id}>
          {section}
        </div>
      )
    }
  })

  return sections
}

export default function CareerPage({ data }) {
  const page = data.allWpPage.nodes[0]
  const sections = getSections(page)

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short",
        }}
      />
      <JobsHero
        title={page.title}
        heroTitle={page.acfWorkForUsHero.wfuHeroTitle}
        uri={page.uri}
      />
      <div style={{ overflow: "hidden" }}>
        {sections && sections.map(component => component)}
        <LatestBlogPost />
      </div>
    </>
  )
}

export const query = graphql`query ($slug: String!) {
  allWpPage(filter: {slug: {eq: $slug}}) {
    nodes {
      id
      uri
      title
      link
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED)
            }
          }
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        schema {
          raw
        }
      }
      id
      title
      slug
      template {
        templateName
      }
      acfWorkForUs {
        navigation {
          fieldGroupName
          scrollToId
          title
          type
        }
        workForUsSections {
          ... on WpPage_Acfworkforus_WorkForUsSections_JobFeed {
            fieldGroupName
            moreInfo
            title
            currentJobs {
              ... on WpJob {
                id
                uri
                title
                status
                acfJobfields {
                  jobType
                }
              }
            }
          }
          ... on WpPage_Acfworkforus_WorkForUsSections_OurTeam {
            fieldGroupName
          }
          ... on WpPage_Acfworkforus_WorkForUsSections_WeWorkWith {
            fieldGroupName
          }
          ... on WpPage_Acfworkforus_WorkForUsSections_CompanyValues {
            fieldGroupName
            introContent
            wellbeing {
              title
              icon {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 120
                      transformOptions: {cropFocus: CENTER}
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
            videoPlaceholder {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 538, layout: CONSTRAINED)
                }
              }
            }
            videoEmbed
            supportTitle
            supportLink {
              target
              title
              url
            }
            supportIntro
            perks {
              content
              icon {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(width: 43, height: 43, layout: FIXED)
                  }
                }
              }
              title
            }
          }
          ... on WpPage_Acfworkforus_WorkForUsSections_NoRecruitersPlease {
            fieldGroupName
          }
        }
      }
      acfWorkForUsHero {
        wfuHeroTitle
      }
    }
  }
}
`
