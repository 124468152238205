import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BlogPosts } from "./BlogPosts"

const LatestBlogPost = ({ title }) => {
  const {
    allWpPost: { posts },
  } = useStaticQuery(graphql`{
  allWpPost(limit: 2, sort: {fields: date, order: DESC}) {
    posts: nodes {
      title
      id
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 584
                height: 400
                placeholder: NONE
                transformOptions: {fit: COVER, cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      mobileImage: featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 480
                quality: 40
                placeholder: NONE
                transformOptions: {fit: COVER, cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`)

  return (
    posts && <BlogPosts title={title || `Latest from the blog`} posts={posts} />
  )
}

export default LatestBlogPost
