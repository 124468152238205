import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SwiperCore, { Navigation, Pagination } from "swiper"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"
import { StaffSlider } from "@styles/organisms/Sliders"
import StaffModule from "../modules/StaffModule"
import styled from "styled-components"
import Heading from "@components/ui/Heading"

// Import Swiper styles
// install Swiper components
SwiperCore.use([Navigation, Pagination])

const MeetTheTeamStyled = styled.section`
  padding: 40px 0;
  background-color: var(--color-secondary);

  h2 {
    margin-top: 0;
  }

  .container {
    padding: 0;
  }
`

const MeetTheTeam = props => {
  const data = useStaticQuery(graphql`
    fragment portrait on WpMediaItem {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 279
            height: 335
            quality: 95
            transformOptions: { fit: COVER, cropFocus: CENTER }
            layout: FIXED
          )
        }
      }
    }

    {
      wp {
        staff {
          ourTeam {
            staffDetails {
              social {
                type
                id
              }
              role
              name
              portrait2 {
                ...portrait
              }
              portrait {
                ...portrait
              }
            }
          }
        }
      }
    }
  `)

  const staffDetails = data.wp.staff.ourTeam.staffDetails

  const updateIndicator = ({ activeIndex, slides }) => {
    if (typeof document !== "undefined") {
      document.querySelector(".swiper-indicator").innerHTML =
        activeIndex + 1 + "/" + slides.length
    }
  }

  return (
    <div id="team_section">
      <MeetTheTeamStyled className="bg-orange">
        <div className="container">
          <Heading
            node="h2"
            data-aos="fade-up"
            data-aos-duration="1000"
            underlined
            color="white"
          >
            Meet the team
          </Heading>

          <StaffSlider>
            <Swiper
              onInit={updateIndicator}
              onSlideChange={updateIndicator}
              navigation={{
                nextEl: ".button-next",
                prevEl: ".button-prev",
              }}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="${className}"></span>`
                },
              }}
              breakpoints={{
                100: {
                  loop: false,
                  slidesPerView: "auto",
                  slidesPerColumn: 1,
                  spaceBetween: 10,
                  slidesPerGroup: 1,
                  centeredSlides: true,
                },
                520: {
                  loop: false,
                  slidesPerView: 2,
                  slidesPerColumn: 2,
                  spaceBetween: 30,
                  slidesPerGroup: 4,
                },
                768: {
                  slidesPerView: 3,
                  slidesPerColumn: 2,
                  slidesPerGroup: 3,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 4,
                  slidesPerColumn: 2,
                  slidesPerGroup: 4,
                  spaceBetween: 30,
                },
              }}
            >
              {staffDetails.map((staffDetail, key) => (
                <SwiperSlide key={key}>
                  <StaffModule {...staffDetail} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="button-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-left"
                className="svg-inline--fa fa-angle-left fa-w-8"
                role="img"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                />
              </svg>
            </div>
            <div className="button-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                className="svg-inline--fa fa-angle-right fa-w-8"
                role="img"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                />
              </svg>
            </div>
            <div className="swiper-indicator" />
          </StaffSlider>
        </div>
      </MeetTheTeamStyled>
    </div>
  )
}

//TODO:Add prop types
MeetTheTeam.propTypes = {}

export default MeetTheTeam
