import React from "react"

import { HeroStyleConstrained } from "@styles/organisms/Hero"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import PeopleImage from "../../../assets/images/bgs/pointers.jpg"
import HeroHead from "./HeroHead"
import Helmet from "react-helmet"
import Heading from "@components/ui/Heading"

const JobsHeroStyle = styled(HeroStyleConstrained)`
  min-height: 0;
  height: 634px;
  overflow: hidden;

  .container {
    align-items: flex-start;
    padding-top: 127px;

    .inner {
      max-width: 814px;
      z-index: 1;
    }
  }

  .hero-head {
    .breadcrumbs {
      ${breakpointsMedia(breakpoints.desktopSM, "max")} {
        display: none;
      }
    }
  }

  .people-wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .people {
    height: 328px;
    width: 681.91px;

    background: url(${PeopleImage}) no-repeat center;
    background-size: contain;
  }
`

export default function JobsHero({ title, uri, heroTitle }) {
  const breadcrumbs = [
    {
      title: title,
    },
  ]

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={PeopleImage} />
      </Helmet>
      <JobsHeroStyle>
        <div className="container">
          <HeroHead hasHome={true} breadcrumbs={breadcrumbs} uri={uri} />
          <div className="inner">
            <Heading
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              {heroTitle || title}
            </Heading>
          </div>
          <div className="people-wrap">
            <div className="people" data-aos="fade-up" />
          </div>
        </div>
      </JobsHeroStyle>
    </>
  )
}
