import React from "react"
import PropTypes from "prop-types"
import JobModule from "../modules/JobModule"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

export const JobSectionStyled = styled.section`
  margin: 50px 0;

  .job-content {
    padding-top: 80px;
    margin: auto;
    max-width: 841px;
    font-size: 17px;
    line-height: 27px;
    text-align: center;
  }

  .job-feed-wrap {
    position: relative;
    margin: auto;
    max-width: 946px;
    display: table;
  }

  .job-feed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 14px;
    position: relative;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin: 0 -20px;
    }

    .job-module {
      margin: 20px;
    }
  }
`

const JobFeedSection = ({ title, content, jobs }) => {
  return (
    <JobSectionStyled>
      <div className="container">
        <div className="clearfix text-center">
          <div data-aos="fade" data-aos-duration="1000">
            <Heading node="h3">{title}</Heading>
          </div>
        </div>
        <div className="job-feed-wrap">
          <div className="job-feed">
            {jobs &&
              jobs.map((job, key) => {
                return (
                  <JobModule
                    key={key}
                    {...job}
                    jobType={job?.acfJobfields?.jobType}
                  />
                )
              })}
          </div>
        </div>
        {content && (
          <div
            className="job-content "
            data-aos="fade-up"
            data-aos-duration="1000"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </JobSectionStyled>
  )
}

JobFeedSection.propTypes = {
  title: PropTypes.string,
  jobs: PropTypes.array.isRequired,
  content: PropTypes.string,
}
JobFeedSection.defaultProps = {
  title: `Vacancies`,
}

export default JobFeedSection
