import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints, breakpointsMedia } from "../variables/Breakpoint"
import Colors from "../variables/Colors"

export const StaffSlider = styled(motion.div)`
  margin-top: 38px;

  .swiper-indicator {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 33px;
    color: white;
    margin-top: 30px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      display: none;
    }
  }

  .button-next,
  .button-prev {
    width: 58px;
    height: 58px;
    color: var(--color-text-light);
    font-size: 58px;
    line-height: 58px;
    text-align: center;
    border-radius: 13px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    @media (max-width: 1270px) {
      display: none;
    }
  }

  .button-prev {
    left: -55px;

    @media (min-width: 1400px) {
      left: -70px;
    }
  }

  .button-next {
    right: -55px;

    @media (min-width: 1400px) {
      right: -70px;
    }
  }

  .swiper-pagination {
    margin-top: 33px;
    text-align: center;
    display: none;

    @media (min-width: 520px) {
      display: block;
    }
  }

  .swiper-pagination-bullet {
    opacity: 1;
    height: 24px;
    width: 24px;
    border: 5px solid #ffffff;
    border-radius: 100%;
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    background-color: white;
    transition: all 0.15s;

    &:hover {
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.cerise};
  }

  .swiper-wrapper {
    @media (min-width: 520px) {
      height: 700px;
    }
  }

  .swiper-slide {
    width: 279px;
    height: 398px;

    @media (min-width: 520px) {
      width: 100%;
      height: calc((100% - 30px) / 2);
    }
  }
`
