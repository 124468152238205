import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import * as basicLightbox from "basiclightbox"
import "basiclightbox/dist/basicLightbox.min.css"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import OurPerks from "./OurPerks"
import { GatsbyImage } from "gatsby-plugin-image"
import Heading from "@components/ui/Heading"

const CompanyValuesStyled = styled.section`
  background-color: #f8f7f7;
  padding: 40px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 62px 0;
  }

  hr {
    border-top: 1px solid var(--color-secondary);
    margin-top: 40px;
    margin-bottom: 40px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  h2 {
    margin-top: 0;
  }

  .intro-wrap {
    display: flex;
    padding-bottom: 30px;
    padding-top: 22px;
    flex-direction: column;

    ${breakpointsMedia(breakpoints.desktop)} {
      flex-direction: row;
    }

    .intro-content {
      flex: 1;
      order: 2;

      ${breakpointsMedia(breakpoints.desktop)} {
        order: 1;
        margin-right: 40px;
      }

      p:first-child {
        margin-top: 0;
      }
    }

    .video-wrap {
      order: 1;
      margin-bottom: 25px;

      ${breakpointsMedia(breakpoints.desktop)} {
        order: 2;
        flex: 0 0 538px;
        margin-bottom: 0;
      }

      .video-image-wrap {
        margin: auto;
      }

      .video-image {
        margin: auto;

        width: 100%;
        max-width: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
        cursor: pointer;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          width: 538px;
        }

        ${breakpointsMedia(breakpoints.tablet)} {
          padding-bottom: 0;
          height: 343.02px;
        }

        &:before {
          content: "";
          display: block;
          height: 51px;
          width: 51px;
          border-radius: 5px;
          background-color: var(--color-primary);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.25s;
          z-index: 1;

          ${breakpointsMedia(breakpoints.tabletWide)} {
            height: 82px;
            width: 82px;
          }
        }

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 0 8px 23px;
          border-color: transparent transparent transparent var(--color-bg);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;

          ${breakpointsMedia(breakpoints.tabletWide)} {
            border-width: 18.5px 0 18.5px 26px;
          }
        }

        &:hover {
          &:before {
            background-color: var(--color-primary);
            opacity: 0.7;
          }
        }
      }
    }
  }
`

export const CompanyValues = ({
  title,
  introContent,
  children,
  perks,
  videoPlaceholder,
  videoEmbed,
}) => {
  const openLightbox = () => {
    basicLightbox
      .create(videoEmbed, {
        className: "iframe-lightbox",
      })
      .show()
  }

  return (
    <CompanyValuesStyled className="company-values-section">
      <div className="container">
        <Heading node="h2" data-aos="fade" data-aos-duration="1000" underlined>
          {title}
        </Heading>
        <div className="intro-wrap" id="company_values_con">
          <div
            className="intro-content  "
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="100"
            dangerouslySetInnerHTML={{ __html: introContent }}
          />
          {videoEmbed && (
            <div
              className="video-wrap  "
              data-aos="fade"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <div
                className="video-image"
                onClick={openLightbox}
                onKeyPress={openLightbox}
                role="button"
                tabIndex="0"
              >
                <GatsbyImage
                  image={
                    videoPlaceholder?.localFile?.childImageSharp
                      ?.gatsbyImageData
                  }
                />
              </div>
            </div>
          )}
        </div>
        {perks && <OurPerks perks={perks} />}
      </div>
      <hr />
      {children}
    </CompanyValuesStyled>
  )
}

CompanyValues.defaultProps = {
  title: `Company values`,
}

CompanyValues.propTypes = {
  title: PropTypes.string,
  introContent: PropTypes.string,
  perks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      icon: PropTypes.object,
    })
  ),
  videoPlaceholder: PropTypes.object,
  videoEmbed: PropTypes.string,
}

export default CompanyValues
