import React from "react"
import PropTypes from "prop-types"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { FontFamily } from "@styles/variables/FontFamily"
import Heading from "@components/ui/Heading"

const OurPerksStyled = styled.div`
  padding-top: 30px;

  .perks {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin: 0 -25px;
    }

    .perk {
      display: flex;
      flex: 100%;
      padding-bottom: 25px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        flex: 0 0 50%;
        padding: 25px;
      }

      .icon {
        flex: 0 0 43px;
        height: 43px;
        width: 43px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 21px;
      }

      .title {
        font-family: ${FontFamily.Heading};
        font-size: 20px;
        line-height: 33px;
        font-weight: 600;
      }
    }
  }
`

const OurPerks = ({ title, perks }) => {
  return (
    <OurPerksStyled id="value_perks">
      <div className="text-center  " data-aos="fade" data-aos-duration="1000">
        <Heading node="h3">{title}</Heading>
      </div>
      <div className="perks">
        {perks.map((perk, key) => {
          return (
            <div
              key={key}
              className="perk  "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <CustomGatsbyImage className={"icon"} {...perk.icon.localFile} />
              <div className="content-wrap">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: perk.title }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: perk.content }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </OurPerksStyled>
  )
}

OurPerks.propTypes = {
  title: PropTypes.string,
  perks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      icon: PropTypes.object,
    })
  ).isRequired,
}

OurPerks.defaultProps = {
  title: `A few of our perks`,
}

export default OurPerks
