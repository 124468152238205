import { graphql, useStaticQuery } from "gatsby"

const useClientLogos = () => {
  const { allWpPortfolio } = useStaticQuery(
    graphql`{
  allWpPortfolio(
    limit: 10
    filter: {acfPortfolio: {featuredImage: {localFile: {id: {ne: null}}}}}
  ) {
    nodes {
      title
      acfPortfolio {
        clientUrl
        featuredImage {
          altText
          localFile {
            extension
            childImageSharp {
              gatsbyImageData(
                width: 130
                layout: CONSTRAINED
              )
            }
            publicURL
          }
        }
      }
    }
  }
}
`
  )

  return allWpPortfolio.nodes?.map(portfolio => portfolio.acfPortfolio)
}

export default useClientLogos
