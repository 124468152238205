import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Heading from "@components/ui/Heading"
const JobModuleLinkStyled = styled(props => <Link {...props} />)`
  height: 286px;
  width: 287px;
  box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.11);
  text-decoration: none;
  padding: 26px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background: white;

  .job-icon {
    height: 36px;
    width: 48px;
    opacity: 0.4;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    bottom: 22px;
    right: 31px;
    filter: grayscale(1);
    transition: all 0.25s;
  }

  .job-title {
    text-decoration: none;
  }

  --offset: 0;
  --top: var(--offset);
  --bottom: var(--offset);
  --right: var(--offset);
  --left: var(--offset);

  &:before,
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    z-index: -1;
    position: absolute;
    transition: all 1s;
  }

  &:before {
    border-width: 0 66px 66px 0;
    border-color: transparent var(--color-tertiary) transparent transparent;
    top: var(--top);
    right: var(--right);
  }

  &:after {
    border-width: 66px 0 0 66px;
    border-color: transparent transparent transparent var(--color-bg-shade);
    bottom: var(--bottom);
    left: var(--left);
  }

  &:hover,
  &:focus {
    --offset: -96px;
    text-decoration: none;

    .job-icon {
      filter: grayscale(0);
    }

    &:before,
    &:after {
      transition: all 2s;
    }
  }
`

const JobModule = ({ uri, title, jobType }) => {
  return (
    <JobModuleLinkStyled
      className="job-module "
      to={uri}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <Heading node="h3" className="job-title">
        {title}
      </Heading>
      <i className={`job-icon ${jobType}-icon`} />
    </JobModuleLinkStyled>
  )
}

JobModule.propTypes = {
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
}

export default JobModule
