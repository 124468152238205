import React from "react"
import PropTypes from "prop-types"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Button from "@components/ui/buttons/Button"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { FontFamily } from "@styles/variables/FontFamily"
import Heading from "@components/ui/Heading"

const WellBeingStyled = styled.div`
  margin-bottom: 16px;

  .wellbeing-icons {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
    margin-top: 19px;

    ${breakpointsMedia("520px")} {
      margin: 0 -25px;
    }

    .wellbeing-icon {
      display: flex;
      flex: 0 0 150px;
      margin: 11px;
      min-height: 150px;
      width: 150px;
      background-color: var(--color-bg);
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 17px 0;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        flex: 0 0 198px;
        margin: 22px;
        min-height: 198px;
        width: 198px;
        padding: 20px 0;
      }

      .icon {
        height: 47px;
        width: 56px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        margin-bottom: 15px;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          height: 100px;
          width: 120px;
        }
      }

      .title {
        font-family: ${FontFamily.Heading};
        font-size: 20px;
        font-weight: 600;
        padding: 0 18px;
        text-align: center;
        line-height: 24px;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          line-height: 33px;
        }
      }
    }
  }
`

const WellBeing = ({ points }) => {
  return (
    <div className="container">
      <WellBeingStyled>
        <div className="text-center">
          <Heading node="h3" data-aos="fade-up" data-aos-duration="1000">
            We Support Your Wellbeing with Passion
          </Heading>
          <div
            className="text-center  "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p>
              Find out how we look after our employees with our wellbeing
              program
            </p>
          </div>
        </div>
        <div className="wellbeing-icons">
          {points &&
            points.map((point, key) => {
              return (
                <div
                  key={key}
                  className="wellbeing-icon "
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <CustomGatsbyImage
                    className={"icon"}
                    {...point.icon.localFile}
                  />
                  <div className="title">{point.title}</div>
                </div>
              )
            })}
        </div>
      </WellBeingStyled>
      <div className="text-center  " data-aos="fade" data-aos-duration="1000">
        <Button to="/supporting-mental-health-at-work" color="pink">
          Read more
        </Button>
      </div>
    </div>
  )
}

WellBeing.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.object,
    })
  ).isRequired,
}

export default WellBeing
